/* Global styles */
body {
    font-family: sans-serif;
  }
  
  /* Container styles */
  .container {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Form styles */
  .inputForm {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  
  .inputForm label {
    font-size: 16px;
    font-weight: bold;
  }
  
  .inputForm textarea {
    width: 97.5%;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
  }
  
  .inputForm button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: large;
  }
  
  /* Additional styling */
  .inputForm label {
    display: block;
    margin-bottom: 10px;
  }
  
  .inputForm textarea {
    margin-bottom: 20px;
  }
  
  .inputForm button {
    margin-top: 20px;
  }

  .inputContainer {
    display: flex;
    align-items: top;
}

.note {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* margin-top: 1px; */
    margin-left: 20px; /* Adjust the margin as needed */
    font-size: 12px; /* Adjust the font size as needed */
    color: #666; /* Adjust the color as needed */
    font-size: small;
}