.downloadButton {
    width: 100%;
    padding: 10px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: large; 
    margin-top: 20px;
  };